<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Grafik Pengeluaran <b>Klinik Hayandra</b>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Chart></Chart>
          </template>
        </Card>

      </div>
    </div>

  </div>
</template>

<script>


import Card from '@/view/content/Card.vue'
import Chart from '@/component/report-charts/ExpenseChart.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Chart,
    
  },

  data() {
    return {
      // data
      summary:[],
      // Table Items
      items: [],
      // Filter
      filter: {
        start_date: '',
        end_date: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1
      
    }
  },

  methods: {

    // async pagination() {
    //   let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
    //   let response = await module.paginate(`expense-reports`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
    //   this.summary = await module.get(`expense-reports-summary-amount`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
    //   let pagination = response.meta.pagination
    //   this.items = response.data
    //   this.totalRows = pagination.total
    // },

    // pageOnClick(page) {
    //   this.currentPage = page
    //   this.pagination()
    // },

    // filterByPeriode(sended) {
    //   this.filter.start_date = sended.start_date
    //   this.filter.end_date = sended.end_date
    //   this.pagination()
    // },

    // async btnDeleteOnClick(id) {
    //   // Delete Data
    //   let result = await module.delete('expenses/' + id)
    //   // If Deleted
    //   if (result) {
    //     this.pagination()
    //   }
    // },

    // btnPrintOnClick() {
    //   this.$htmlToPaper('print')
    // },

    // btnDownloadOnClick() {
    //   this.$refs.html2Pdf.generatePdf()
    // },

    // onProgress($event) {
    //   console.log("onProgress", $event)
    // },

    // hasStartedGeneration() {
    //   console.log("hasStartedGeneration")
    // },

    // hasGenerated($event) {
    //   console.log("hasGenerated", $event)
    // },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan" },
      { title: "Laporan Pengeluaran" },
      { title: "Grafik Pengeluaran" },
    ])
  
  },

}
</script>

<style>
</style>
